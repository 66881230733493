export default {
	methods: {
		getWindowOpenValues: function () {
			let data = {}
			data.changeQuantity = this.window_values.quantity
			data.changeWidth = this.window_values.width
			data.changeHeight = this.window_values.height
			data.changeIsolation = this.window_values.active_isolation
			data.changeFTypeGlass = this.window_values.active_type_of_glass.first
			data.changeSTypeGlass = this.window_values.active_type_of_glass.second
			data.changeHorizontalGrid = this.window_values.horizontal_grid
			data.changeVerticalGrid = this.window_values.vertical_grid
			data.changeInsideFrameColor = this.window_values.active_colors.inside
			data.changeOutsideFrameColor = this.window_values.active_colors.outside
			data.changeHandleSide = this.window_values.handle_side
			data.changeHandleColor = this.window_values.active_handle_colors
			data.changeNotspor = this.window_values.notspor
			data.changeChildsafe = this.window_values.childsafe

			return data
		},

		getWindowFixedValues: function () {
			let data = {}
			data.changeQuantity = this.window_values.quantity
			data.changeWidth = this.window_values.width
			data.changeHeight = this.window_values.height
			data.changeIsolation = this.window_values.active_isolation
			data.changeFTypeGlass = this.window_values.active_type_of_glass.first
			data.changeSTypeGlass = this.window_values.active_type_of_glass.second
			data.changeHorizontalGrid = this.window_values.horizontal_grid
			data.changeVerticalGrid = this.window_values.vertical_grid
			data.changeInsideFrameColor = this.window_values.active_colors.inside
			data.changeOutsideFrameColor = this.window_values.active_colors.outside
			data.changeHandleColor = this.window_values.active_handle_colors
			data.changeNotspor = this.window_values.notspor
			data.changeChildsafe = this.window_values.childsafe

			return data
		},

		getBalconyDoorValues: function () {
			let data = {}
			data.changeQuantity = this.window_values.quantity
			data.changeWidth = this.window_values.width
			data.changeHeight = this.window_values.height
			data.changeInsideFrameColor = this.window_values.active_colors.inside
			data.changeOutsideFrameColor = this.window_values.active_colors.outside
			data.changeHandleColor = this.window_values.active_handle_colors
			data.changeNotspor = this.window_values.notspor
			data.changeDoorbrake = this.window_values.doorbrake
			data.changeLockcylinder = this.window_values.lockcylinder
			data.changeThreshold = this.window_values.threshold
			data.changeHorizontalGrid = this.window_values.horizontal_grid
			data.changeVerticalGrid = this.window_values.vertical_grid
			data.changeBrystning = this.window_values.brystning
			data.changeOpeningDirection = this.window_values.slarentning
			data.changeFTypeGlass = this.window_values.active_type_of_glass.first
			data.changeSTypeGlass = this.window_values.active_type_of_glass.second
			data.changeIsolation = this.window_values.active_isolation

			return data
		},

		getOuterDoorValues: function () {
			let data = {}
			data.changeQuantity = this.window_values.quantity
			data.changeWidth = this.window_values.width
			data.changeHeight = this.window_values.height
			data.changeInsideFrameColor = this.window_values.active_colors.inside
			data.changeOutsideFrameColor = this.window_values.active_colors.outside
			data.changeHandleColor = this.window_values.active_handle_colors
			data.changeNotspor = this.window_values.notspor
			data.changeLockcylinder = this.window_values.lockcylinder
			data.changeThreshold = this.window_values.threshold
			data.changeHorizontalGrid = this.window_values.horizontal_grid
			data.changeVerticalGrid = this.window_values.vertical_grid
			data.changeBrystning = this.window_values.brystning
			data.changeOpeningDirection = this.window_values.slarentning
			data.changeFTypeGlass = this.window_values.active_type_of_glass.first
			data.changeSTypeGlass = this.window_values.active_type_of_glass.second
			data.changeIsolation = this.window_values.active_isolation

			return data
		},


        getWindowMultipleValues: function () {
            let data = {}

                const element = this.window_values[this.selectedWindow];
                    data.changeQuantity =  element.quantity;
                    data.changeWidth = element.width;
                    data.changeHeight = element.height;
                    data.changeInsideFrameColor = element.active_colors.inside;
                    data.changeOutsideFrameColor = element.active_colors.outside;
                    data.changeHandleColor = element.active_handle_colors;
                    data.changeNotspor = element.notspor;
                    data.changeLockcylinder = element.lockcylinder;
                    data.changeThreshold = element.threshold;
                    data.changeHorizontalGrid =  element.horizontal_grid;
                    data.changeVerticalGrid =  element.vertical_grid;
                    data.changeBrystning =  element.brystning;
                    data.changeOpeningDirection =  element.slarentning;
                    data.changeFTypeGlass =  element.active_type_of_glass.first;
                    data.changeSTypeGlass =  element.active_type_of_glass.second;
                    data.changeIsolation =  element.active_isolation;
                    data.type =  'window multiple';
                    data.window_type = this.window_type
                    data.changeHandleSide = element.handle_side ?? null
                    data.changeChildsafe = element.childsafe ?? null

            return data
        },
	}
}
